export const onboardingRoutes = [
    {
        path: 'OnboardingPages/ObWelcome',
        route: 'ob-welcome',
    },
    {
        path: 'OnboardingPages/ObFirstStep',
        route: 'ob-first-step',
    },
    {
        path: 'OnboardingPages/ObAmazing',
        route: 'ob-amazing',
    },
    {
        path: 'OnboardingPages/ObBenefits',
        route: 'ob-benefits',
    },
    {
        path: 'OnboardingPages/ObBenefitsHarvard',
        route: 'ob-benefits-harvard',
    },
    {
        path: 'OnboardingPages/ObDailyGoalFeedback',
        route: 'ob-daily-goal-feedback',
    },
    {
        path: 'OnboardingPages/ObLevel',
        route: 'ob-level',
    },
    {
        path: 'OnboardingPages/ObRisk',
        route: 'ob-risk',
    },
    {
        path: 'OnboardingPages/ObHealthFeedback',
        route: 'ob-health-feedback',
    },
    {
        path: 'OnboardingPages/ObThank',
        route: 'ob-thankyou',
    },
    {
        path: 'OnboardingPages/ObSmartwatchFeedback',
        route: 'ob-smartwatch-feedback',
    },
    {
        path: 'OnboardingPages/ObFortunately',
        route: 'ob-fortunately',
    },
    {
        path: 'OnboardingPages/ObSocialProof',
        route: 'ob-social-proof',
    },
    {
        path: 'OnboardingPages/ObSocialProofFull',
        route: 'ob-social-proof-full',
    },
    {
        path: 'OnboardingPages/ObGoalsOver',
        route: 'ob-goals',
    },
    {
        path: 'OnboardingPages/CurrentBody',
        route: 'ob-current-body-type',
    },
    {
        path: 'OnboardingPages/TargetBody',
        route: 'ob-target-body-type',
    },
    {
        path: 'OnboardingPages/FitnessLevel',
        route: 'ob-fitness-level',
    },
    {
        path: 'OnboardingPages/ObTargetZonesC',
        route: 'ob-target-zones',
    },
    {
        path: 'OnboardingPages/ObTargetZonesA',
        route: 'ob-target-zones-a',
    },
    {
        path: 'OnboardingPages/ObTargetZonesFull',
        route: 'ob-target-zones-full',
    },
    {
        path: 'OnboardingPages/ObHealthLoader',
        route: 'ob-health-loader',
    },
    {
        path: 'OnboardingPages/BodyImage',
        route: 'ob-body-image',
    },
    {
        path: 'OnboardingPages/ActivityLevel',
        route: 'ob-activity-level',
    },
    {
        path: 'OnboardingPages/ObWalking',
        route: 'ob-fitness-walking',
    },
    {
        path: 'OnboardingPages/ObWalkingActivity',
        route: 'ob-walking-activity',
    },
    {
        path: 'OnboardingPages/ObHiking',
        route: 'ob-hiking',
    },
    {
        path: 'OnboardingPages/ObDefActivity',
        route: 'ob-default-activity',
    },
    {
        path: 'OnboardingPages/ObDailyGoal',
        route: 'ob-daily-goal',
    },
    {
        path: 'OnboardingPages/ObPace',
        route: 'ob-pace',
    },
    {
        path: 'OnboardingPages/ObWork',
        route: 'ob-work',
    },
    {
        path: 'OnboardingPages/ObSleep',
        route: 'ob-sleep',
    },
    {
        path: 'OnboardingPages/PushupsSquats',
        route: 'ob-pushups-squats',
    },
    {
        path: 'OnboardingPages/YogaLevel',
        route: 'ob-yoga-level',
    },
    {
        path: 'OnboardingPages/BendingKnees',
        route: 'ob-bending-knees',
    },
    {
        path: 'OnboardingPages/MealsFeel',
        route: 'ob-meals-feel',
    },
    {
        path: 'OnboardingPages/ObSleep',
        route: 'ob-sleep',
    },
    {
        path: 'OnboardingPages/ObWater',
        route: 'ob-water',
    },
    {
        path: 'OnboardingPages/ObSmartwatch',
        route: 'ob-smartwatch',
    },
    {
        path: 'OnboardingPages/ObDiets',
        route: 'ob-diets',
    },
    {
        path: 'OnboardingPages/ObGender',
        route: 'ob-gender',
    },
    {
        path: 'OnboardingPages/ObReasons',
        route: 'ob-reasons',
    },
    {
        path: 'OnboardingPages/ObSpecialActivity',
        route: 'ob-special-activity',
    },
    {
        path: 'OnboardingPages/ObSmartwatchType',
        route: 'ob-smartwatch-type',
    },
    {
        path: 'OnboardingPages/ObBadHabits',
        route: 'ob-bad-habits',
    },
    {
        path: 'OnboardingPages/ObBackKneesIssues',
        route: 'ob-back-knees-issues',
    },
    {
        path: 'OnboardingPages/ObHealth',
        route: 'ob-health',
    },
    {
        path: 'OnboardingPages/ObMedication',
        route: 'ob-medication',
    },
    {
        path: 'OnboardingPages/ObUnfortunately',
        route: 'ob-unfortunately',
    },
    {
        path: 'OnboardingPages/ObHeight',
        route: 'ob-height',
    },
    {
        path: 'OnboardingPages/ObCurrentWeight',
        route: 'ob-weight',
    },
    {
        path: 'OnboardingPages/ObTargetWeight',
        route: 'ob-weight-target',
    },
    {
        path: 'OnboardingPages/ObAge',
        route: 'ob-age',
    },
    {
        path: 'OnboardingPages/FeedbackSummary',
        route: 'ob-feedback-summary',
    },
    {
        path: 'OnboardingPages/CreatingA',
        route: 'creating',
    },
    {
        path: 'OnboardingPages/EmailA',
        route: 'email-a',
    },
    {
        path: 'OnboardingPages/Email',
        route: 'email',
    },
    {
        path: 'OnboardingPages/EmailConsent',
        route: 'email-consent',
    },
    {
        path: 'OnboardingPages/EmailConsentFull',
        route: 'email-consent-full',
    },
    {
        path: 'OnboardingPages/ObTags',
        route: 'ob-tags',
    },
    {
        path: 'OnboardingPages/ObTagsB',
        route: 'ob-tags-b',
    },
    {
        path: 'OnboardingPages/ObOccasion',
        route: 'ob-occasion',
    },
    {
        path: 'OnboardingPages/ObOccasion',
        route: 'ob-occasion-a',
    },
    {
        path: 'OnboardingPages/ObMedications',
        route: 'ob-medications',
    },
    {
        path: 'OnboardingPages/ObPregnant',
        route: 'ob-pregnant',
    },
    {
        path: 'OnboardingPages/ObYogaEffect',
        route: 'ob-yoga-effect',
    },
    {
        path: 'OnboardingPages/ObFlexibility',
        route: 'ob-flexibility',
    },
    {
        path: 'OnboardingPages/ObRestrictiveDiet',
        route: 'ob-restrictive-diet',
    },
    {
        path: 'OnboardingPages/ObFlexibility',
        route: 'ob-flexibility',
    },
    {
        path: 'OnboardingPages/ObThank',
        route: 'ob-thankyou',
    },
    {
        path: 'OnboardingPages/PilatesLevel',
        route: 'ob-pilates-level',
    },
    {
        path: 'OnboardingPages/EmailAccount',
        route: 'email-account',
    },
    {
        path: 'OnboardingPages/EmailB',
        route: 'email-b',
    },
    {
        path: 'OnboardingPages/EmailC',
        route: 'email-c',
    },
    {
        path: 'OnboardingPages/EmailAFull',
        route: 'email-a-full',
    },
    {
        path: 'OnboardingPages/EmailAccountA',
        route: 'email-account-a',
    },
    {
        path: 'OnboardingPages/ObSocialProofAges',
        route: 'ob-social-proof-ages',
    },
    {
        path: 'OnboardingPages/ObSocialProofMix',
        route: 'ob-social-proof-mix',
    },
    {
        path: 'OnboardingPages/ObGoalsOverMix',
        route: 'ob-goals-mix',
    },
    {
        path: 'OnboardingPages/CurrentBodyMix',
        route: 'ob-body-type-mix',
    },
    {
        path: 'OnboardingPages/TargetBodyMix',
        route: 'ob-body-type-target-mix',
    },
    {
        path: 'OnboardingPages/TargetZonesMix',
        route: 'ob-target-zones-mix',
    },
    {
        path: 'OnboardingPages/ObFamiliarityFull',
        route: 'ob-familiarity-full',
    },
    {
        path: 'OnboardingPages/ObTargetZonesB',
        route: 'ob-target-zones-b',
    },
    {
        path: 'OnboardingPages/ObTargetZonesC',
        route: 'ob-target-zones-c',
    },
    {
        path: 'OnboardingPages/ObGoalsA',
        route: 'ob-goals-a',
    },
    {
        path: 'OnboardingPages/ObGoalsB',
        route: 'ob-goals-b',
    },
    {
        path: 'OnboardingPages/ObGoalsFeedbackA',
        route: 'ob-goals-feedback-a',
    },
    {
        path: 'OnboardingPages/ObGoalsFeedbackB',
        route: 'ob-goals-feedback-b',
    },
    {
        path: 'OnboardingPages/CurrentBodyA',
        route: 'ob-current-body-type-a',
    },
    {
        path: 'OnboardingPages/TargetBodyA',
        route: 'ob-target-body-type-a',
    },
    {
        path: 'OnboardingPages/ObYogaLevelPlanSocproof',
        route: 'ob-yoga-level-plan-socproof',
    },
    {
        path: 'OnboardingPages/ObYogaLevelDetailsCoach',
        route: 'ob-yoga-level-details-coach',
    },
    {
        path: 'OnboardingPages/ObSex',
        route: 'ob-sex',
    },
    {
        path: 'OnboardingPages/ObGoalsMeditation',
        route: 'ob-goals-meditation',
    },
    {
        path: 'OnboardingPages/ObCurrentEmotions',
        route: 'ob-current-emotions',
    },
    {
        path: 'OnboardingPages/ObPositive',
        route: 'ob-positive',
    },
    {
        path: 'OnboardingPages/ObUnfortunatelyStress',
        route: 'ob-unfortunately-stress',
    },
    {
        path: 'OnboardingPages/ObStressLook',
        route: 'ob-stress-look',
    },
    {
        path: 'OnboardingPages/ObStressTime',
        route: 'ob-stress-time',
    },
    {
        path: 'OnboardingPages/ObSleepProblems',
        route: 'ob-sleep-problems',
    },
    {
        path: 'OnboardingPages/ObFocus',
        route: 'ob-focus',
    },
    {
        path: 'OnboardingPages/ObSelftime',
        route: 'ob-selftime',
    },
    {
        path: 'OnboardingPages/ObGeneral',
        route: 'ob-general',
    },
    {
        path: 'OnboardingPages/ObGeneral',
        route: 'ob-general1',
    },
    {
        path: 'OnboardingPages/ObGeneral',
        route: 'ob-general2',
    },
    {
        path: 'OnboardingPages/ObGeneral',
        route: 'ob-general3',
    },
    {
        path: 'OnboardingPages/ObGeneral',
        route: 'ob-general4',
    },
    {
        path: 'OnboardingPages/ObGeneral',
        route: 'ob-general5',
    },
    {
        path: 'OnboardingPages/ObGeneral',
        route: 'ob-general6',
    },
    {
        path: 'OnboardingPages/ObGeneral',
        route: 'ob-general7',
    },
    {
        path: 'OnboardingPages/ObGeneral',
        route: 'ob-general8',
    },
    {
        path: 'OnboardingPages/ObGeneral',
        route: 'ob-general9',
    },
    {
        path: 'OnboardingPages/ObGeneral',
        route: 'ob-general10',
    },
    {
        path: 'OnboardingPages/FeedbackSummaryMeditation',
        route: 'feedback-summary-meditation',
    },
    {
        path: 'OnboardingPages/EmailAFull2',
        route: 'email-a-full2',
    },
    {
        path: 'OnboardingPages/EmailConsentFull2',
        route: 'email-consent-full2',
    },
    {
        path: 'OnboardingPages/ObSleepFull',
        route: 'ob-sleep-full',
    },
    {
        path: 'OnboardingPages/FeedbackSummaryFitLevelFull',
        route: 'feedback-summary-fitlevel-full',
    },
    {
        path: 'OnboardingPages/ObFeedback',
        route: 'ob-feedback',
    },
    {
        path: 'OnboardingPages/ObFeedback',
        route: 'ob-feedback-a',
    },
    {
        path: 'OnboardingPages/ObFeedback',
        route: 'ob-feedback-b',
    },
    {
        path: 'OnboardingPages/ObFeedback',
        route: 'ob-feedback-c',
    },
    {
        path: 'OnboardingPages/ObFeedback',
        route: 'ob-feedback-d',
    },
    {
        path: 'OnboardingPages/ObFeedback',
        route: 'ob-feedback-e',
    },
    {
        path: 'OnboardingPages/ObFeedback',
        route: 'ob-feedback-f',
    },
    {
        path: 'OnboardingPages/ObUserStory',
        route: 'ob-user-story',
    },
    {
        path: 'OnboardingPages/ObUserStoryFm',
        route: 'ob-user-story-fm',
    },
    {
        path: 'OnboardingPages/CreatingConfig/CreatingAConfig',
        route: 'creating-a',
    },
    {
        path: 'OnboardingPages/CreatingConfig/CreatingBConfig',
        route: 'creating-b',
    },
    {
        path: 'OnboardingPages/CreatingConfig/CreatingCConfig',
        route: 'creating-c',
    },
    {
        path: 'OnboardingPages/CreatingConfig/CreatingDConfig',
        route: 'creating-d',
    },
    {
        path: 'OnboardingPages/EmailD',
        route: 'email-d',
    },
    {
        path: 'OnboardingPages/ObSeeIntro',
        route: 'ob-see-intro',
    },
    {
        path: 'OnboardingPages/ObSeeVariable',
        route: 'ob-see-1',
    },
    {
        path: 'OnboardingPages/ObSeeVariable',
        route: 'ob-see-2',
    },
    {
        path: 'OnboardingPages/ObSeeVariable',
        route: 'ob-see-3',
    },
    {
        path: 'OnboardingPages/ObWalkingFeedback',
        route: 'ob-walking-feedback',
    },
    {
        path: 'OnboardingPages/ObPushupsSquatsFeedback',
        route: 'ob-pushups-squats-feedback',
    },
    {
        path: 'OnboardingPages/ObBendingKneesFeedback',
        route: 'ob-bending-knees-feedback',
    },
    {
        path: 'OnboardingPages/ObSleepFullFeedback',
        route: 'ob-sleep-full-feedback',
    },
    {
        path: 'OnboardingPages/ObWaterFeedback',
        route: 'ob-water-feedback',
    },
    {
        path: 'OnboardingPages/ObFamiliarityFullFeedback',
        route: 'ob-familiarity-full-feedback',
    },
    {
        path: 'OnboardingPages/ObFeedbackDes',
        route: 'ob-feedback-a-des',
    },
    {
        path: 'OnboardingPages/ObFeedbackDes',
        route: 'ob-feedback-b-des',
    },
    {
        path: 'OnboardingPages/ObFeedbackDes',
        route: 'ob-feedback-c-des',
    },
    {
        path: 'OnboardingPages/ObFeedbackDes',
        route: 'ob-feedback-d-des',
    },
    {
        path: 'OnboardingPages/ObFeedbackDes',
        route: 'ob-feedback-e-des',
    },
    {
        path: 'OnboardingPages/ObFeedbackDes',
        route: 'ob-feedback-f-des',
    },
    {
        path: 'OnboardingPages/ObHeightDes',
        route: 'ob-height-des',
    },
    {
        path: 'OnboardingPages/ObCurrentWeightDes',
        route: 'ob-weight-des',
    },
    {
        path: 'OnboardingPages/ObTargetWeightDes',
        route: 'ob-weight-target-des',
    },
    {
        path: 'OnboardingPages/ObAgeDes',
        route: 'ob-age-des',
    },
    {
        path: 'OnboardingPages/ObSocialProofDes',
        route: 'ob-social-proof-des',
    },
    {
        path: 'OnboardingPages/ObFeedbackDes',
        route: 'ob-feedback-des',
    },
    {
        path: 'OnboardingPages/ObGoalsDes',
        route: 'ob-goals-des',
    },
    {
        path: 'OnboardingPages/ObFamiliarityFullDes',
        route: 'ob-familiarity-full-des',
    },
    {
        path: 'OnboardingPages/CurrentBodyADes',
        route: 'ob-current-body-type-a-des',
    },
    {
        path: 'OnboardingPages/TargetBodyADes',
        route: 'ob-target-body-type-a-des',
    },
    {
        path: 'OnboardingPages/ObTargetZonesCDes',
        route: 'ob-target-zones-c-des',
    },
    {
        path: 'OnboardingPages/BodyImageDes',
        route: 'ob-body-image-des',
    },
    {
        path: 'OnboardingPages/ActivityLevelDes',
        route: 'ob-activity-level-des',
    },
    {
        path: 'OnboardingPages/ObWalkingDes',
        route: 'ob-walking-des',
    },
    {
        path: 'OnboardingPages/PushupsSquatsDes',
        route: 'ob-pushups-squats-des',
    },
    {
        path: 'OnboardingPages/PilatesLevelDes',
        route: 'ob-pilates-level-des',
    },
    {
        path: 'OnboardingPages/BendingKneesDes',
        route: 'ob-bending-knees-des',
    },
    {
        path: 'OnboardingPages/ObTagsADes',
        route: 'ob-tags-a-des',
    },
    {
        path: 'OnboardingPages/MealsFeelDes',
        route: 'ob-meals-feel-des',
    },
    {
        path: 'OnboardingPages/ObSleepDes',
        route: 'ob-sleep-des',
    },
    {
        path: 'OnboardingPages/ObWaterDes',
        route: 'ob-water-des',
    },
    {
        path: 'OnboardingPages/ObDietsDes',
        route: 'ob-diets-des',
    },
    {
        path: 'OnboardingPages/FeedbackSummaryDes',
        route: 'feedback-summary-fitlevel-des',
    },
    {
        path: 'OnboardingPages/ObOccasionDes',
        route: 'ob-occasion-des',
    },
    {
        path: 'OnboardingPages/ObOccasionResultDes',
        route: 'ob-occasion-result-des',
    },
    {
        path: 'OnboardingPages/ObOccasionResultA',
        route: 'ob-occasion-result-a',
    },
    {
        path: 'OnboardingPages/CreatingADes',
        route: 'creating-des',
    },
    {
        path: 'OnboardingPages/EmailAccountADes',
        route: 'email-account-a-des',
    },
    {
        path: 'OnboardingPages/EmailAFullDes',
        route: 'email-a-full-des',
    },
    {
        path: 'OnboardingPages/EmailConsentFullDes',
        route: 'email-consent-full-des',
    },
    {
        path: 'OnboardingPages/YogaLevelDes',
        route: 'ob-yoga-level-des',
    },
    {
        path: 'OnboardingPages/ObGeneralDes',
        route: 'ob-general-des',
    },
    {
        path: 'OnboardingPages/ObGeneralDes',
        route: 'ob-general-des1',
    },
    {
        path: 'OnboardingPages/ObGeneralDes',
        route: 'ob-general-des2',
    },
    {
        path: 'OnboardingPages/ObGeneralDes',
        route: 'ob-general-des3',
    },
    {
        path: 'OnboardingPages/ObGeneralDes',
        route: 'ob-general-des4',
    },
    {
        path: 'OnboardingPages/ObGeneralDes',
        route: 'ob-general-des5',
    },
    {
        path: 'OnboardingPages/ObHealthConditions',
        route: 'ob-health-conditions',
    },
    {
        path: 'OnboardingPages/ObBackIssueConfig',
        route: 'ob-back-issue-config',
    },
    {
        path: 'OnboardingPages/ObCalculatingGraph',
        route: 'ob-calculating-graph',
    },
    {
        path: 'OnboardingPages/ObTrustFeedback',
        route: 'ob-trust-feedback',
    },
    {
        path: 'OnboardingPages/ObWaterfall',
        route: 'ob-waterfall',
    },
    {
        path: 'OnboardingPages/ObCrowded',
        route: 'ob-crowded',
    },
    {
        path: 'OnboardingPages/ObSuitableTrails',
        route: 'ob-suitable-trails',
    },
    {
        path: 'OnboardingPages/ObBadWeather',
        route: 'ob-bad-weather',
    },
    {
        path: 'OnboardingPages/ObTrailDifficulty',
        route: 'ob-trail-difficulty',
    },
    {
        path: 'OnboardingPages/ObBeforehand',
        route: 'ob-beforehand',
    },
    {
        path: 'OnboardingPages/ObGetLost',
        route: 'ob-get-lost',
    },
    {
        path: 'OnboardingPages/ObWeekends',
        route: 'ob-weekends',
    },
    {
        path: 'OnboardingPages/ObPush',
        route: 'ob-push',
    },
    {
        path: 'OnboardingPages/ObBrain',
        route: 'ob-brain',
    },
    {
        path: 'OnboardingPages/ObStressLevel',
        route: 'ob-stress-level',
    },
    {
        path: 'OnboardingPages/ObReasonsA',
        route: 'ob-reasons-a',
    },
    {
        path: 'OnboardingPages/ObNature',
        route: 'ob-nature',
    },
    {
        path: 'OnboardingPages/ObHikingA',
        route: 'ob-hiking-a',
    },
    {
        path: 'OnboardingPages/ObTrailDistance',
        route: 'ob-trail-distance',
    },
    {
        path: 'OnboardingPages/ObPersonType',
        route: 'ob-person-type',
    },
    {
        path: 'OnboardingPages/ObFitnessLevelA',
        route: 'ob-fitness-level-a',
    },
    {
        path: 'OnboardingPages/ObTagsA',
        route: 'ob-tags-a',
    },
    {
        path: 'OnboardingPages/ObTypeHiking',
        route: 'ob-type-hiking',
    },
    {
        path: 'OnboardingPages/ObSpecialActivityA',
        route: 'ob-special-activity-a',
    },
    {
        path: 'OnboardingPages/ObFeelings',
        route: 'ob-feelings',
    },
    {
        path: 'OnboardingPages/ObChallenges',
        route: 'ob-challenges',
    },
    {
        path: 'OnboardingPages/ObInjuries',
        route: 'ob-injuries',
    },
    {
        path: 'OnboardingPages/ObSocialize',
        route: 'ob-socialize',
    },
    {
        path: 'OnboardingPages/ObBusy',
        route: 'ob-busy',
    },
    {
        path: 'OnboardingPages/ObInnerPower',
        route: 'ob-inner-power',
    },
    {
        path: 'OnboardingPages/WelcomeToFottur',
        route: 'welcome-to-fottur',
    },
    {
        path: 'OnboardingPages/ObHikingProfileSummary',
        route: 'ob-hiking-profile-summary',
    },
    {
        path: 'OnboardingPages/ObHealthProfileSummary',
        route: 'ob-health-profile-summary',
    },
    {
        path: 'OnboardingPages/ObClearGoal',
        route: 'ob-clear-goal',
    },
    {
        path: 'OnboardingPages/ObNatureFeedback',
        route: 'ob-nature-feedback',
    },
    {
        path: 'OnboardingPages/ObBenefitsA',
        route: 'ob-benefits-a',
    },
    {
        path: 'OnboardingPages/ObHikingProfile',
        route: 'ob-hiking-profile',
    },
    {
        path: 'OnboardingPages/ObGoodHands',
        route: 'ob-good-hands',
    },
    {
        path: 'OnboardingPages/ObHeathLoader',
        route: 'ob-heath-loader',
    },
    {
        path: 'OnboardingPages/ObInjuriesFeedback',
        route: 'ob-injuries-feedback',
    },
    {
        path: 'OnboardingPages/EmailA',
        route: 'email-a',
    },
    {
        path: 'OnboardingPages/ObCrowdedFeedback',
        route: 'ob-crowded-feedback',
    },
    {
        path: 'OnboardingPages/ObSuitableTrailsFeedback',
        route: 'ob-suitable-trails-feedback',
    },
    {
        path: 'OnboardingPages/ObPushFeedback',
        route: 'ob-push-feedback',
    },
    {
        path: 'OnboardingPages/ObPersonTypeFeedback',
        route: 'ob-person-type-feedback',
    },
    {
        path: 'OnboardingPages/ObBrainFeedback',
        route: 'ob-brain-feedback',
    },
    // moc
    {
        path: 'OnboardingPages/CreatingA',
        route: 'ob-reviews',
    },
    // moc
    {
        path: 'OnboardingPages/ObPushFeedback',
        route: 'ob-person-type-feedback',
    },
    // moc
    {
        path: 'OnboardingPages/ObPushFeedback',
        route: 'ob-brain-feedback',
    },
    // moc
    {
        path: 'OnboardingPages/ObPushFeedback',
        route: 'ob-reviews',
    },
    {
        path: 'OnboardingPages/ObPsychologicalProfile',
        route: 'ob-psychological-profile',
    },
    {
        path: 'OnboardingPages/ObHikingLoader',
        route: 'ob-hiking-loader',
    },
    {
        path: 'OnboardingPages/ObEmotions',
        route: 'ob-emotions',
    },
    {
        path: 'OnboardingPages/ObDriven',
        route: 'ob-driven',
    },
    {
        path: 'OnboardingPages/ObWalkingMeditation',
        route: 'ob-walking-meditation',
    },
    {
        path: 'OnboardingPages/ObStressReasons',
        route: 'ob-stress-reasons',
    },
    {
        path: 'OnboardingPages/ObHabits',
        route: 'ob-habits',
    },
    {
        path: 'OnboardingPages/ObProcrastination',
        route: 'ob-procrastination',
    },
    {
        path: 'OnboardingPages/ObSleepIssues',
        route: 'ob-sleep-issues',
    },
    {
        path: 'OnboardingPages/ObTraumaExperience',
        route: 'ob-trauma-experience',
    },
    {
        path: 'OnboardingPages/ObTraumaInfo',
        route: 'ob-trauma-info',
    },
    {
        path: 'OnboardingPages/ObTraumaChange',
        route: 'ob-trauma-change',
    },
    {
        path: 'OnboardingPages/ObTraumaProfile',
        route: 'ob-trauma-profile',
    },
    {
        path: 'OnboardingPages/ObStartAge',
        route: 'ob-start-age',
    },
    {
        path: 'OnboardingPages/ObCommitment',
        route: 'ob-commitment',
    },
    {
        path: 'OnboardingPages/ObReasonsMulti',
        route: 'ob-reasons-multi',
    },
    {
        path: 'OnboardingPages/ObChooseState',
        route: 'ob-choose-state',
    },
    {
        path: 'OnboardingPages/ObTrailPersonalization1',
        route: 'ob-trail-personalization-1',
    },
    {
        path: 'OnboardingPages/ObTrailPersonalization2',
        route: 'ob-trail-personalization-2',
    },
    {
        path: 'OnboardingPages/ObTrailPersonalization3',
        route: 'ob-trail-personalization-3',
    },
    {
        path: 'OnboardingPages/ObCommitmentSomatic',
        route: 'ob-commitment-somatic',
    },
    {
        path: 'OnboardingPages/ObSummaryFitLevel',
        route: 'ob-summary-fit-level',
    },
    {
        path: 'OnboardingPages/DynamicSingleSelect',
        route: 'ob-reasons-somatic',
    },
    {
        path: 'OnboardingPages/DynamicSingleSelect',
        route: 'ob-stress',
    },
    {
        path: 'OnboardingPages/DynamicSingleSelect',
        route: 'ob-exhausted',
    },
    {
        path: 'OnboardingPages/DynamicSingleSelect',
        route: 'ob-stored-emotions',
    },
    {
        path: 'OnboardingPages/DynamicSingleSelect',
        route: 'ob-relaxed',
    },
    {
        path: 'OnboardingPages/DynamicSingleSelect',
        route: 'ob-body-tension',
    },
    {
        path: 'OnboardingPages/DynamicSingleSelect',
        route: 'ob-headaches',
    },
    {
        path: 'OnboardingPages/DynamicSingleSelect',
        route: 'ob-somatic-hiking',
    },
    {
        path: 'OnboardingPages/DynamicSingleSelect',
        route: 'ob-balance',
    },
    {
        path: 'OnboardingPages/DynamicSingleSelect',
        route: 'ob-best-shape',
    },
    {
        path: 'OnboardingPages/DynamicSingleSelect',
        route: 'ob-selfcare-time',
    },
    {
        path: 'OnboardingPages/DynamicSingleSelect',
        route: 'ob-selfcare-daily',
    },
    {
        path: 'OnboardingPages/DynamicSingleSelect',
        route: 'ob-motivation-a',
    },
    {
        path: 'OnboardingPages/DynamicSingleSelect',
        route: 'ob-emotional-traumas',
    },
    {
        path: 'OnboardingPages/DynamicMultiSelect',
        route: 'ob-trauma-impact',
    },
    {
        path: 'OnboardingPages/DynamicMultiSelect',
        route: 'ob-positive-emotions',
    },
    {
        path: 'OnboardingPages/DynamicMultiSelect',
        route: 'ob-smartwatch-type',
    },
    {
        path: 'OnboardingPages/DynamicMultiSelect',
        route: 'ob-tension-area',
    },
    {
        path: 'OnboardingPages/DynamicFeedback',
        route: 'ob-welcome-somatic',
    },
    {
        path: 'OnboardingPages/DynamicFeedback',
        route: 'ob-stress-response',
    },
    {
        path: 'OnboardingPages/DynamicFeedback',
        route: 'ob-physical-response',
    },
    {
        path: 'OnboardingPages/DynamicFeedback',
        route: 'ob-trauma-info-a',
    },
    {
        path: 'OnboardingPages/ObTraumaChange',
        route: 'ob-trauma-change-a',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObGender',
        route: 'ob-gender-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObHiking',
        route: 'ob-hiking-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObNature',
        route: 'ob-nature-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObWalking',
        route: 'ob-fitness-walking-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObSmartwatch',
        route: 'ob-smartwatch-feedback-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObReasons',
        route: 'ob-reasons-b',
    },
    {
        path: 'OnboardingPages/ObTrailDistance',
        route: 'ob-trail-distance-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObBackKneesIssues',
        route: 'ob-back-knees-issues-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObInjuries',
        route: 'ob-injuries-b',
    },
    {
        path: 'OnboardingPages/ObHealth',
        route: 'ob-health-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObPersonType',
        route: 'ob-person-type-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObFeelings',
        route: 'ob-feelings-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObChallenges',
        route: 'ob-challenges-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObWaterfall',
        route: 'ob-waterfall-b',
    },

    {
        path: 'OnboardingPages/GreenTheme/ObCrowded',
        route: 'ob-crowded-b',
    },

    {
        path: 'OnboardingPages/GreenTheme/ObSuitableTrails',
        route: 'ob-suitable-trails-b',
    },

    {
        path: 'OnboardingPages/GreenTheme/ObBeforehand',
        route: 'ob-beforehand-b',
    },

    {
        path: 'OnboardingPages/GreenTheme/ObGetLost',
        route: 'ob-get-lost-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObBadWeather',
        route: 'ob-bad-weather-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObTrailDifficulty',
        route: 'ob-trail-difficulty-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObWeekends',
        route: 'ob-weekends-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObBrain',
        route: 'ob-brain-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObStressLevel',
        route: 'ob-stress-level-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObSocialProof',
        route: 'ob-socia-proof-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/Email',
        route: 'ob-email-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObFeedbackSimple',
        route: 'ob-welcome-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObFeedbackSimple',
        route: 'ob-benefits-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObFeedbackSimple',
        route: 'ob-hiking-profile-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObFeedbackSimple',
        route: 'ob-injuries-feedback-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObFeedbackSimple',
        route: 'ob-suitable-trails-feedback-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObFeedbackSimple',
        route: 'ob-risk-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObFeedbackSimple',
        route: 'ob-good-hands-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObFeedbackSimple',
        route: 'ob-brain-feedback-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObPersonTypeFeedback',
        route: 'ob-person-type-feedback-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObPushFeedback',
        route: 'ob-push-feedback-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObCrowdedFeedback',
        route: 'ob-crowded-feedback-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObSocialize',
        route: 'ob-socialize-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObBusy',
        route: 'ob-busy-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObInnerPower',
        route: 'ob-inner-power-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObFitnessLevelB',
        route: 'ob-fitness-level-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/EmailConsent',
        route: 'email-consent-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObNatureFeedback',
        route: 'ob-nature-feedback-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObHikingProfileSummary',
        route: 'ob-hiking-profile-summary-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObTagsC',
        route: 'ob-tags-c',
    },
];
